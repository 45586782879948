@charset "UTF-8";

/*
* Chapter 1 Supplimentary Style
* Chapter 2 Header Style
* Chapter 3 Looking for section Style
* Chapter 4 Experiences list Style
* Chapter 5 Destination list Style
* Chapter 6 Sign up section Style
* Chapter 7 Testimonial section Style
* Chapter 8 Newsletter section Style
* Chapter 9 Footer Style
* Chapter 10 Registration section Style
* Chapter 11 Child Pages Style
*/

/* -------------------------------------------------
Chapter 11 Child Pages Style
------------------------------------------------- */

/* -------------------------------------------------
Chapter 1 Supplimentary Style
------------------------------------------------- */
:root {
    --color-one: #479AD2;
    /* Blue Text */
    --color-two: #3054A0;
    /* Dark Blue Background + Dark Blue Border + Dark Blue text*/
    --color-three: #FBA708;
    /* Orange */
    --color-four: #202020;
    /* Black Text */
    --color-five: #E3E3E3;
    /* Grey Icon */
    --color-six: #DBDBDB;
    /* Border Color */
    --color-seven: #0B2058;
    /* Dark Background */

    --font-pico: 9px;
    --font-nano: 10px;
    --font-micro: 11px;
    --font-uxs: 12px;
    --font-xs: 14px;
    --font-sm: 16px;
    --font-md: 18px;
    --font-lg: 20px;
    --font-xl: 22px;
    --font-2xl: 24px;
    --font-3xl: 26px;
    --font-4xl: 28px;
    --font-5xl: 30px;
    --font-6xl: 32px;

    --wrapper: auto;
}


/* MD */
@media (min-width: 768px) {
    :root {
        --font-pico: 10px;
        --font-nano: 11px;
        --font-micro: 12px;
        --font-uxs: 13px;
        --font-xs: 15px;
        --font-sm: 17px;
        --font-md: 19px;
        --font-lg: 21px;
        --font-xl: 23px;
        --font-2xl: 25px;
        --font-3xl: 27px;
        --font-4xl: 29px;
        --font-5xl: 31px;
        --font-6xl: 33px;

        --wrapper: 720px;
    }
}

/* LG */
@media (min-width: 992px) {
    :root {
        --font-pico: 11px;
        --font-nano: 12px;
        --font-micro: 13px;
        --font-uxs: 14px;
        --font-xs: 16px;
        --font-sm: 18px;
        --font-md: 20px;
        --font-lg: 22px;
        --font-xl: 24px;
        --font-2xl: 26px;
        --font-3xl: 28px;
        --font-4xl: 30px;
        --font-5xl: 32px;
        --font-6xl: 34px;

        --wrapper: 960px;
    }
}

/* XL */
@media (min-width: 1200px) {
    :root {
        --font-pico: 12px;
        --font-nano: 13px;
        --font-micro: 14px;
        --font-uxs: 15px;
        --font-xs: 17px;
        --font-sm: 19px;
        --font-md: 21px;
        --font-lg: 23px;
        --font-xl: 25px;
        --font-2xl: 27px;
        --font-3xl: 29px;
        --font-4xl: 31px;
        --font-5xl: 33px;
        --font-6xl: 35px;

        --wrapper: 1140px;
    }
}

/* XXL */
@media (min-width: 1400px) {
    :root {
        --font-pico: 13px;
        --font-nano: 14px;
        --font-micro: 15px;
        --font-uxs: 16px;
        --font-xs: 18px;
        --font-sm: 20px;
        --font-md: 22px;
        --font-lg: 24px;
        --font-xl: 26px;
        --font-2xl: 28px;
        --font-3xl: 30px;
        --font-4xl: 32px;
        --font-5xl: 34px;
        --font-6xl: 36px;

        --wrapper: 1320px;
    }
}

/* Greater Than XXL */
@media (min-width: 1600px) {
    :root {
        --wrapper: 1542px;
    }
}

.weight-500 {
    font-weight: 500;
}

.weight-600 {
    font-weight: 600;
}

.font-pico {
    font-size: var(--font-pico);
}

.font-nano {
    font-size: var(--font-nano);
}

.font-micro {
    font-size: var(--font-micro);
}

.font-uxs {
    font-size: var(--font-uxs);
}

.font-xs {
    font-size: var(--font-xs);
}

.font-sm {
    font-size: var(--font-sm);
}

.font-md {
    font-size: var(--font-md);
}

.font-lg {
    font-size: var(--font-lg);
}

.font-xl {
    font-size: var(--font-xl);
}

.font-2xl {
    font-size: var(--font-2xl);
}

.font-3xl {
    font-size: var(--font-3xl);
}

.font-4xl {
    font-size: var(--font-4xl);
}

.font-5xl {
    font-size: var(--font-5xl);
}

.font-6xl {
    font-size: var(--font-6xl);
}

body {
    background-color: #F6F6F6;
    font-family: 'Epilogue', sans-serif;
}

a {
    text-decoration: none;
}

.text-one {
    color: var(--color-one);
}

.text-three {
    color: var(--color-three);
}

.wrapper {
    /* border: solid 1px blueviolet; */
    margin: 0 auto;
    width: var(--wrapper);
}

.wrapper-child {
    box-shadow: 0px 3px 22px #00000014;
    border-radius: 20px;
}

.wrapper-child.sw-bg-white {
    background-color: #fff;
    padding: 12px;
}

.wrapper-child.sw-bg-blue {
    background-color: var(--color-two);
    /* padding: calc(var(--font-lg) * 2) calc(var(--font-sm) * 4); */
    padding: calc(var(--font-lg) * 2) 0px 0px 0px;
}

.btn-warning {
    border-radius: 12px; /* 16px */
    background-color: var(--color-three);
    color: #FFFFFF;
    height: 47px; /* 47px */
    min-width: 120px; /* 120px */
}

.btn-primary {
    border-radius: 14px;
    background-color: var(--color-two);
    border-color: var(--color-two);
    color: #FFFFFF;
    height: calc(var(--font-5xl) * 2);
    min-width: 231px;
}

.btn-secondary {
    border-radius: 14px;
    background-color: var(--color-one);
    border-color: var(--color-one);
    color: #FFFFFF;
    height: calc(var(--font-5xl) * 2);
    min-width: 231px;
}

.btn-secondary:hover {
    background-color: #fff;
    border-color: var(--color-one);
    color: var(--color-four);
}

.btn-info {
    border-radius: 13px;
    background-color: var(--color-three);
    border-color: var(--color-three) c;
    color: #FFFFFF;
    height: 60px;
    min-width: 154px;
    padding-left: 40px;
    padding-right: 40px;
}

.btn-info:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
}

.form-control:focus,
.form-select:focus {
    box-shadow: none;
    border-color: transparent;
}

.sw-textbox-info {
    height: 60px;
    border-color: transparent;
}

.sw-btn-sm {
    min-width: 200px;
    height: 44px;
}

@media (min-width: 768px) {
    .wrapper-child.sw-bg-white {
        padding: calc(var(--font-sm) * 5) calc(var(--font-sm) * 4);
    }

    .btn-warning {
        border-radius: 16px; /* 16px */
        height: calc(var(--font-5xl) * 2); /* 47px */
        min-width: 154px; /* 120px */
    }
}

/* -------------------------------------------------
Chapter 11 Child Pages Style
------------------------------------------------- */
.sw-prod-desc-box {
    /* border: solid 1px cyan; */
    display: flex;
    flex-direction: column;
    margin-top: calc(var(--font-5xl) * 2);
    margin-bottom: calc(var(--font-lg) * 4);
}

.sw-prod-desc-left {
    /* border: solid 1px lime; */
    flex: 1;
    margin-right: 0px;
    /* 46 */
    margin-bottom: 46px;
}

.sw-prod-desc-right {
    /* border: solid 1px palevioletred; */
    /* flex: 0 0 500px; */
    background-color: rgba(11, 32, 88, 0.06);
    border-radius: 18px;
}

.sw-information-box {

    padding: calc(var(--font-lg) * 2) calc(var(--font-md) * 2);
}

.sw-page-sub-header {
    font-weight: 500;
    font-size: var(--font-xl);
    line-height: var(--font-xl);
    letter-spacing: 0px;
    color: var(--color-four);
    margin-bottom: var(--font-3xl);
}

.sw-information-box p {
    font-size: var(--font-uxs);
    line-height: var(--font-md);
}

.sw-esperienza-box {
    /* border: solid 1px red; */
    /* padding: calc(var(--font-lg) * 2) 0px; */
}

.sw-esperienza-box p {
    font-size: var(--font-xs);
    line-height: var(--font-lg);
}

.sw-program-detail-box {
    margin-top: calc(var(--font-md) * 2);
}

.sw-pd-wrap {
    display: flex;
    flex-direction: row;
}

.sw-pd-icon {
    font-size: var(--font-sm);
    margin-right: 8px;
}

.sw-pd-desc {
    font-size: var(--font-uxs);
    line-height: var(--font-md);
}

.sw-sec-checkout-option {
    /* border: solid 1px red; */
    z-index: 1;
    margin-bottom: 100px;
}

.sw-checkout-header {
    font-weight: 500;
    font-size: var(--font-md);
    line-height: var(--font-xl);
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-bottom: var(--font-2xl);
    padding: 0px calc(var(--font-sm) * 4);

}

.sw-checkout-form {
    padding: 0px calc(calc(var(--font-sm) * 4) - 12px) calc(var(--font-lg) * 2);
    /* margin-bottom: calc(var(--font-lg) * 1); */
}

.sw-modal-wrap-temp {
    margin-top: 100px;
}

.sw-modal-wrap-temp .modal-dialog {
    border: solid 1px #20202041;
    box-shadow: 0px 3px 22px #00000014;
    border-radius: 20px;
}

.sw-collapse {
    border-top: 1px solid rgba(235, 235, 235, 0.41);
}

.sw-checkout-options-box {
    padding: 12px 24px;
}

.sw-check-opt-single {
    background-color: #fff;
    box-shadow: 0px 3px 12px #00000029;
    border-radius: 18px;
    padding: var(--font-2xl);
    margin-bottom: var(--font-xl);

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.sw-check-opt-desc {
    flex: 0 0 100%;
    /* border: solid 1px grey; */
    font-size: var(--font-nano);
    line-height: var(--font-sm);
    letter-spacing: 0px;
    color: var(--color-four);
    display: flex;
    justify-content: right;
    margin-bottom: 6px;
}

.sw-total-details-rate-lg,
.sw-total-details-rate-md {
    font-weight: 600;
    font-size: var(--font-xl);
    line-height: var(--font-xl);
    letter-spacing: 0px;
    color: var(--color-four);
    text-align: left;
    /* border: solid 1px red; */
}

.sw-total-details-rate-lg {
    flex: 0 0 50%;
    margin-bottom: 6px;
}

.sw-check-opt-action {
    /* margin-left: calc(var(--font-sm) * 2); */
    margin-left: 0px;
    /* border: solid 2px lime; */
    flex: 0 0 50%;
    text-align: right;
}

.sw-check-opt-desc i,
.sw-check-opt-desc svg {
    color: var(--color-one);
}

.sw-check-opt-desc span {
    margin-left: 9px;
}

.blink {
    animation: blinker 1s linear infinite;
    color: yellow;
    cursor: pointer;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.sw-add-services-box {
    background-color: #fff;
    box-shadow: 0px 3px 12px #00000029;
    border-radius: 18px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    /* padding: var(--font-2xl); */
}

.sw-add-services-single {
    border: 1px solid #EBEBEB;
    padding: var(--font-2xl);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.sw-add-ser-check {
    /* border: solid 1px red; */
}

.sw-add-ser-desc {
    /* border: solid 1px blue; */
    font-size: var(--font-uxs);
    line-height: var(--font-md);
    letter-spacing: 0px;
    color: var(--color-four);
    flex: 1;
}

.sw-total-details-rate-md {
    flex: 0 0 120px;
    text-align: right;
}

.form-check-input[type="checkbox"] {
    border-radius: 0px;
}

.form-check-input:checked {
    background-color: var(--color-one);
    border-color: var(--color-one);
}

.sw-check-contact .form-check-label {
    font-size: var(--font-nano);
    letter-spacing: 0px;
    color: var(--color-four);
}

.sw-check-contact a {
    font-size: var(--font-nano);
}

.sw-check-contact .form-check-input:checked {
    background-color: #000;
    border-color: #000;
}

.sw-contact-tagline {
    font-weight: 500;
    font-size: var(--font-xl);
    line-height: var(--font-5xl);
    letter-spacing: 0px;
    margin-top: calc(var(--font-2xl) * 2);
}

.sw-contact-info {
    display: flex;
    flex-direction: column;
    font-size: var(--font-xs);
    margin: calc(var(--font-3xl) * 3) 0px;
}

.sw-ci-child {
    margin-right: var(--font-3xl);
}

.sw-sec-route {
    margin-top: calc(var(--font-sm) * 3);
}

.sw-route-map {
    border-radius: 36px;
    height: 470px;
    overflow: hidden;
    margin-top: 20px;
}

.sw-route-map img,
.sw-about-thumbnail-sm img,
.sw-about-thumbnail-lg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* contain-width & cover-height */
    object-position: center center;
}

.sw-route-header {
    font-weight: 500;
    font-size: var(--font-5xl);
    letter-spacing: 0px;
    color: var(--color-four);
}

.sw-route-desc {
    font-size: var(--font-xs);
    line-height: var(--font-3xl);
    letter-spacing: 0px;
    color: var(--color-four);
    margin-top: var(--font-sm);
}

.sw-route-btn {
    margin-top: calc(var(--font-sm) * 3);
}

.sw-about-thumbnail-sm,
.sw-about-thumbnail-lg {
    border-radius: 20px;
    overflow: hidden;
}

.sw-about-thumbnail-sm {
    height: 470px;
}

.sw-about-thumbnail-lg {
    height: 400px;
}

.sw-about-header {
    font-weight: normal;
    font-size: calc(var(--font-md) * 2);
    line-height: calc(var(--font-md) * 2);
    letter-spacing: 0px;
}

.sw-about-para {
    font-size: var(--font-uxs);
    line-height: var(--font-sm);
    margin-top: var(--font-5xl);
}

.sw-sec-why {
    /* border: solid 2px blue; */
    margin-bottom: 113px;
}

.sw-why-box {
    /* border: solid 2px palevioletred; */
    margin-bottom: var(--font-3xl);
}

.sw-why-box:last-child {
    margin-bottom: 0px;
}

.sw-why-icon {
    font-size: var(--font-5xl);
    color: var(--color-one);
}

.sw-why-desc {
    font-weight: 300;
    font-size: var(--font-md);
    line-height: var(--font-2xl);
    letter-spacing: 0px;
    color: var(--color-four);
}

@media (min-width: 768px) {
    .sw-checkout-options-box {
        padding: calc(var(--font-lg) * 2) calc(var(--font-sm) * 4);
    }

    .sw-contact-info {
        flex-direction: row;
    }

    .sw-check-opt-desc {
        margin-bottom: 0px;
    }

    .sw-total-details-rate-lg {
        margin-bottom: 0px;
    }

}

@media (min-width: 992px) {
    .sw-check-opt-desc {
        flex: 1;
    }

    .sw-total-details-rate-lg {
        flex: 0 0 200px;
        text-align: right;
    }

    .sw-check-opt-action {
        margin-left: calc(var(--font-sm) * 2);
        flex: 0 0 200px;
    }
}

@media (min-width: 1200px) {
    .sw-prod-desc-box {
        flex-direction: row;
    }

    .sw-prod-desc-left {
        margin-right: 23px;
        /* 46 */
        margin-bottom: 0px;
    }

    .sw-prod-desc-right {
        flex: 0 0 370px;
    }

    .sw-add-services-single {
        width: 50%;
    }

    .sw-route-map {
        margin-top: 0px;
    }
}

@media (min-width: 1400px) {
    .sw-prod-desc-left {
        margin-right: 46px;
        /* 46 */
    }

    .sw-prod-desc-right {
        flex: 0 0 450px;
    }
}

@media (min-width: 1600px) {
    .sw-prod-desc-right {
        flex: 0 0 500px;
    }
}

/* -------------------------------------------------
Chapter 10 Registration section Style
------------------------------------------------- */
.sw-sec-registration,
.sw-sec-prod-detail,
.sw-sec-exp-listing {
    margin-top: -150px;
    z-index: 1;
    /* border: solid 2px blue; */
}

.sw-sec-registration {
    margin-bottom: 113px;
}

.sw-sec-prod-detail {
    margin-bottom: 90px;
}

.sw-sec-exp-listing {
    margin-bottom: 64px;
}

.sw-page-header {
    font-size: var(--font-3xl);
    line-height: var(--font-3xl);
    letter-spacing: 0px;
    color: var(--color-four);
    /* border: solid 1px black; */
}

.sw-page-header-sm {
    font-size: var(--font-xs);
    line-height: var(--font-xs);
    letter-spacing: 0px;
    color: var(--color-four);
}

.sw-total-box {
    margin-top: var(--font-6xl);
    /* margin-bottom: calc(var(--font-3xl) * 2); */
    /* border: solid 1px greenyellow; */
}

.sw-total-header {
    background-color: #fcfcfc;
    border: 1px solid #DBDBDB;
    border-radius: 14px 14px 0px 0px;
    padding: var(--font-sm) var(--font-6xl);
    font-weight: 500;
    font-size: var(--font-sm);
    line-height: var(--font-2xl);
    color: var(--color-one);
}

.sw-total-details {
    background-color: #fcfcfc;
    padding: var(--font-sm) var(--font-6xl);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DBDBDB;
    border-top: none;
}

.sw-total-details-desc {
    font-size: var(--font-nano);
    line-height: var(--font-sm);
    letter-spacing: 0px;
    color: var(--color-four);
    flex: 1;
    /* border: solid 1px blue; */
}

.sw-total-details-rate {
    font-weight: 600;
    font-size: var(--font-lg);
    line-height: var(--font-2xl);
    letter-spacing: 0px;
    color: var(--color-four);
    text-align: right;
    /* border: solid 1px red; */
    flex: 0 0 130px;
}

.sw-total-footer {
    background-color: #479AD227;
    border: 1px solid #DBDBDB;
    border-radius: 0px 0px 14px 14px;
    padding: var(--font-sm) var(--font-6xl);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.sw-total-label {
    font-weight: 500;
    font-size: var(--font-xs);
    line-height: var(--font-2xl);
    letter-spacing: 0px;
    color: var(--color-four);
    flex: 1;
    /* border: solid 1px blue; */
    text-align: right;
}

.sw-form-title {
    font-weight: 500;
    font-size: var(--font-xs);
    line-height: var(--font-6xl);
    letter-spacing: 0px;
    color: var(--color-one);
    margin-bottom: 20px;
    margin-top: calc(var(--font-3xl) * 2);
}

.form-control.sw-textbox,
.form-select.sw-selectbox {
    border-radius: 14px;
    border-color: var(--color-six);
    padding: var(--font-xl) calc(var(--font-6xl) + 2px);
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0px;
    color: var(--color-four);
}

.form-control.sw-textbox:focus,
.form-select.sw-selectbox:focus,
.form-control.sw-textbox.active,
.form-select.sw-selectbox.active {
    border-color: var(--color-one);
    box-shadow: 0px 3px 22px #479AD25F;
}

.invalid-feedback {
    display: block;
}

.form-control.sw-textbox.error,
.form-select.sw-selectbox.error {
    border-color: #dc3545;
    color: #dc3545;
}

.sw-form-note {
    font-size: var(--font-nano);
    /* line-height: var(--font-6xl); */
    letter-spacing: 0px;
    color: var(--color-four);
}

.sw-form-sub-title {
    font-size: var(--font-uxs);
    line-height: var(--font-uxs);
    letter-spacing: 0px;
    color: var(--color-four);
    margin-bottom: 10px;
    margin-top: 28px;
}

input[type="file"].sw-file-upload {
    opacity: 0;
    /* make transparent */
    z-index: -1;
    /* move under anything else */
    position: absolute;
    /* don't let it take up space */
    /* border: solid 2px blue; */
    top: 0px;
    left: 0px;
}

input[type="file"].sw-file-upload:focus+label {
    outline: 2px solid;
    /* example focus style */
}

.sw-file-upload-control {
    display: block;
    width: 100%;
    padding: var(--font-xl) calc(var(--font-6xl) + 2px);
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0px;
    color: var(--color-four);
    background-color: #F4F4F4;
    background-clip: padding-box;
    border: 1px solid #DBDBDB;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 14px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
    text-align: center;
    /* border: solid 2px red; */
}

.sw-file-upload-control:focus-visible {
    border-color: transparent;
}

.sw-label {
    font-weight: 500;
    font-size: var(--font-xs);
    line-height: var(--font-xs);
    letter-spacing: 0px;
    color: var(--color-one);
    margin-bottom: 14px;
}

.sw-label.white {
    color: #fff;
}

/* custom checkbox for sidebar filter */
.sw-container {
    display: block;
    position: relative;
    padding-left: 55px;
    /* margin-bottom: 8px; */
    cursor: pointer;
    font-size: 16px;
    line-height: 34px;
    /* same as checkmark height */
    font-weight: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: var(--color-four);
    /* border: solid 2px lightsalmon; */
}

.sw-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 34px;
    width: 34px;
    background-color: #fff;
    border: 1px solid #707070;
    border-radius: 8px;
}

.sw-container:hover input~.checkmark {
    background-color: #F4F4F4;
}

.sw-container input:checked~.checkmark {
    background-color: #479AD227;
    border-color: var(--color-one);
}

.sw-container input:checked~.sw-checkbox-label {
    /* color: var(--color-three); */
    font-weight: 500;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.sw-container input:checked~.checkmark:after {
    display: block;
}

.sw-container .checkmark:after {
    left: 12px;
    top: 3px;
    width: 10px;
    height: 20px;
    border: solid var(--color-one);
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sw-invia {
    margin-top: 46px;
    display: flex;
    justify-content: flex-end;
}


@media (min-width: 1200px) {

    .sw-sec-registration,
    .sw-sec-prod-detail,
    .sw-sec-exp-listing {
        margin-top: -200px;
    }
}

@media (min-width: 1400px) {

    .sw-sec-registration,
    .sw-sec-prod-detail,
    .sw-sec-exp-listing {
        margin-top: -250px;
    }
}

@media (min-width: 1600px) {

    .sw-sec-registration,
    .sw-sec-prod-detail,
    .sw-sec-exp-listing {
        margin-top: -300px;
    }
}

/* -------------------------------------------------
Chapter 9 Footer Style
------------------------------------------------- */
footer {
    background-color: #F6F6F6;
    padding: 190px 0px 130px 0px;
}

.sw-footer-desc {
    font-size: var(--font-uxs);
    line-height: var(--font-md);
    letter-spacing: 0px;
    color: var(--color-four);
}

.sw-footer-address {
    font-weight: 500;
    font-size: var(--font-uxs);
    line-height: var(--font-2xl);
    color: var(--color-four);
    margin-top: 40px;
}

.sw-footer-header {
    font-weight: 500;
    font-size: var(--font-sm);
    line-height: var(--font-md);
    color: var(--color-four);
    margin-bottom: var(--font-xl);
    margin-top: 40px;
}

.sw-footer-list {
    margin: 0;
    padding: 0;
}

.sw-footer-list li {
    list-style: none;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0px;
    color: var(--color-four);
}

.sw-footer-list li a,
.sw-footer-social a {
    color: var(--color-four);
}

.sw-footer-list li a:hover,
.sw-footer-social a:hover {
    color: var(--color-one);
}

.sw-footer-social {
    font-size: var(--font-4xl);
    margin-top: 16px;
}

@media (min-width: 1200px) {
    .sw-footer-header {
        margin-top: 0px;
    }
}

/* -------------------------------------------------
Chapter 7 Testimonial section Style
------------------------------------------------- */
.sw-sec-testimonial {
    margin-top: calc(var(--font-3xl) * 6); /* 180px */
    margin-bottom: calc(var(--font-3xl) * 5); /* 155px */
}

.sw-testi-list-container {
    margin-top: 55px;
}

.sw-testi-box {
    border: 1px solid #3054A0;
    border-radius: 46px 46px 0px 46px;
    padding: 18px 34px;
    display: flex !important;
    flex-direction: row;
    flex: 0 0 470px;
    margin: 0px 9px;
}

.sw-testi-thumbnail {
    height: 70px;
    flex: 0 0 70px;
    border-radius: 50%;
    /* width: 70px; */
    overflow: hidden;
    /* border: solid 1px grey; */
}

.sw-testi-thumbnail img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* contain-width & cover-height */
    object-position: center center;
}

.sw-testi-data {
    margin-left: 30px;
    flex: 1;
}

.sw-testi-name {
    font-weight: 600;
    font-size: var(--font-sm);
    line-height: calc(var(--font-sm) + 1px);
    letter-spacing: 0px;
    color: var(--color-four);
}

.sw-testi-ratigs {
    color: var(--color-three);
    text-align: right;
    font-size: var(--font-nano);
}

.sw-testi-desc {
    margin-top: 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* -------------------------------------------------
Chapter 8 Newsletter section Style
------------------------------------------------- */
.sw-sec-newsletter,
.sw-newsletter-blob-box {
    /* height: 350px; */
    /* 600 */
    height: auto;
}

.sw-sec-newsletter {
    /* margin-top: 155px; */
    background-color: #3054A00D;
    overflow: hidden;
    /* background-color: red; */
}

.sw-sec-newsletter-box {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sw-newsletter-data-box {
    /* border: solid 1px red; */
    width: 100%;
    /* z-index: 4; */
    padding: 12px;
}

.sw-newsletter-blob-box {
    display: none;
    /* border: solid 2px blue; */
    width: 50%;
}

.sw-newsletter-thumbnail {
    width: 505px;
    height: 540px;
    /* border: solid 2px cyan; */
    bottom: 0px;

    left: 0;
    right: 0;
    margin: 0 auto;
}

.sw-newsletter-thumbnail img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* contain-width & cover-height */
    object-position: center center;
}

.sw-newsletter-blob-back,
.sw-newsletter-blob-front {
    top: -120px;
    height: 800px;
    /* 800 */
    width: 800px;
}

.sw-newsletter-blob-front {}

.sw-newsletter-blob-back {
    rotate: 30deg;
}

.sw-newsletter-blob-back svg {
    opacity: 0.16;
}

.sw-newsletter-data-wrap {
    width: 100%;
    /* 250 px */
    /* 585px */
    /* border: solid 1px blue; */
}

.sw-newsletter-header {
    font-size: var(--font-5xl);
    line-height: calc(var(--font-5xl) + 1px);
    letter-spacing: 0px;
    color: var(--color-four);
}

.sw-newsletter-sub-header {
    font-size: var(--font-uxs);
    line-height: calc(var(--font-uxs) + 1px);
    letter-spacing: 0px;
    color: var(--color-four);
    margin-top: 20px;
}

.sw-newsletter-control-box {
    margin-top: 50px;
    /* border: solid 1px lime; */
}

.sw-signup-control-box {
    margin-top: var(--font-6xl);
}

.sw-input-group-box {
    background-color: #fff;
    box-shadow: 0px 3px 22px #00000014;
    border-radius: 14px;
    padding: 5px;

    display: flex;
    flex-direction: row;
}

@media (min-width: 768px) {

    .sw-newsletter-blob-box {
        display: block;
    }

    .sw-newsletter-data-box {
        width: 50%;
        padding-left: 0px;
    }

    .sw-sec-newsletter,
    .sw-newsletter-blob-box {
        height: 350px;
        /* 600 */
    }

    .sw-newsletter-blob-back,
    .sw-newsletter-blob-front {
        top: -80px;
        left: -70px;
        height: 500px;
        /* 800 */
        width: 500px;
    }

    .sw-newsletter-thumbnail {
        width: 300px;
        /* 505 */
        height: 320px;
        /* 540 */
    }

    .sw-newsletter-data-wrap {
        width: 350px;
        /* 585px */
    }
}

@media (min-width: 992px) {

    .sw-newsletter-blob-back,
    .sw-newsletter-blob-front {
        left: 0px;
    }

    .sw-newsletter-data-wrap {
        width: 470px;
        /* 585px */
    }
}

@media (min-width: 1200px) {

    .sw-sec-newsletter,
    .sw-newsletter-blob-box {
        height: 450px;
        /* 600 */
    }

    .sw-newsletter-blob-back,
    .sw-newsletter-blob-front {
        top: -100px;
        height: 600px;
        /* 800 */
        width: 600px;
    }

    .sw-newsletter-thumbnail {
        width: 383px;
        /* 505 */
        height: 410px;
        /* 540 */
    }

    .sw-newsletter-data-wrap {
        width: 550px;
        /* 585px */
    }
}

@media (min-width: 1400px) {

    .sw-sec-newsletter,
    .sw-newsletter-blob-box {
        height: 500px;
        /* 600 */
    }

    .sw-newsletter-blob-back,
    .sw-newsletter-blob-front {
        top: -110px;
        height: 700px;
        /* 800 */
        width: 700px;
    }

    .sw-newsletter-thumbnail {
        width: 440px;
        /* 505 */
        height: 470px;
        /* 540 */
    }

    .sw-newsletter-data-wrap {
        width: 585px;
        /* 585px */
    }
}

@media (min-width: 1600px) {


    .sw-sec-newsletter,
    .sw-newsletter-blob-box {
        height: 600px;
        /* 600 */
    }

    .sw-newsletter-blob-back,
    .sw-newsletter-blob-front {
        top: -120px;
        height: 800px;
        /* 800 */
        width: 800px;
    }

    .sw-newsletter-thumbnail {
        width: 505px;
        /* 505 */
        height: 540px;
        /* 540 */
    }
}

/* -------------------------------------------------
Chapter 6 Sign up section Style
------------------------------------------------- */
.sw-sec-signup,
.sw-signup-blob-box {
    /* height: 325px; */
    /* 600px */
    height: auto;
}

.sw-sec-signup {
    margin-top: calc(var(--font-2xl) * 6); /* 175px */
    background-color: #3054A00D;
    /* background-color: pink; */
    overflow: hidden;
}

.sw-sec-signup-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* border: solid 2px cyan; */
    margin-top: 24px;
}

.sw-signup-data-box {
    /* border: solid 1px red; */
    width: 100%;
    padding-left: 12px;
}

.sw-signup-data-wrap {
    width: 320px;
    /* 250px */
    /* border: solid 2px black; */
    /* 700px */

}

.sw-signup-header {
    font-weight: normal;
    font-size: var(--font-3xl);
    line-height: calc(var(--font-3xl) + 1px);
    letter-spacing: 0px;
    color: var(--color-four);
}

.sw-signup-sub-header {
    font-weight: normal;
    font-size: calc(var(--font-sm) + 1px);
    line-height: var(--font-md);
    letter-spacing: 0px;
    color: var(--color-four);
    margin-top: 10px;
}

.sw-signup-options {
    /* border: solid 1px blue; */
    margin: 0;
    padding: 0;
    margin-top: 55px;
}

.sw-signup-options li {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #CECECE;
    padding: 11px 0px;
}

.sw-signup-options li:last-child {
    border-bottom: none;
}

.sw-signup-options li span {
    font-weight: 500;
    font-size: var(--font-sm);
    letter-spacing: 0px;
    color: var(--color-two);
}

.sw-signup-options li i,
.sw-signup-options li svg {
    color: var(--color-two);
    font-size: var(--font-lg);
    padding-right: 8px;
    transition: all 500ms ease 0s;
}

.sw-signup-options li:hover i,
.sw-signup-options li:hover svg {
    transform: translate(5px, 0px);
}

.sw-signup-blob-box {
    display: none;
    /* border: solid 2px blue; */
    width: 50%;
}

.sw-signup-blob-back,
.sw-signup-blob-front {
    top: -90px;
    /* -150px */
    left: -15px;
    /* 0 */

    height: 400px;
    /* 700px */
    width: 400px;
}

.sw-signup-blob-front {}

.sw-signup-blob-back {
    margin-left: 30px;
    rotate: 20deg;
}

.sw-signup-blob-back svg {
    opacity: 0.16;
}

.sw-signup-thumbnail {
    width: 327px;
    /* 560 */
    height: 350px;
    /* 600 */

    /* border: solid 2px cyan; */
    top: 0px;
    left: -50px;
}

.sw-signup-thumbnail img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* contain-width & cover-height */
    object-position: center center;
}

.modal-content {
    border-radius: 20px;
}

.sw-modal-body {
    padding: calc(var(--font-2xl) * 2);
}

.sw-modal-close {
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: var(--font-md);
    color: var(--color-four);
}

.sw-modal-close:hover {
    color: red;
}

.sw-signup-title {
    font-weight: 500;
    font-size: var(--font-md);
    line-height: var(--font-2xl);
    letter-spacing: 0px;
    color: var(--color-four);
}

.sw-signup-subtitle {
    font-weight: normal;
    font-size: var(--font-uxs);
    line-height: var(--font-xs);
    letter-spacing: 0px;
    color: var(--color-four);
    margin-top: var(--font-2xl);
}

@media (min-width: 768px) {
    .sw-sec-signup-box {
        margin-top: 0px;
    }

    .sw-signup-blob-box {
        display: block;
    }

    .sw-signup-data-box {
        width: 50%;
        padding-left: 0px;
    }

    .sw-sec-signup {
        /* margin-top: 175px; */
    }
    
    .sw-sec-signup,
    .sw-signup-blob-box {
        height: 350px;
        /* 600px */
    }

    .sw-signup-blob-back,
    .sw-signup-blob-front {
        top: -90px;
        /* -150px */
        left: -15px;
        /* 0 */

        height: 400px;
        /* 700px */
        width: 400px;
    }

    .sw-signup-thumbnail {
        width: 327px;
        /* 560 */
        height: 350px;
        /* 600 */
    }

    .sw-signup-data-wrap {
        width: 310px;
        /* 700px */
    }
}

@media (min-width: 992px) {
    .sw-signup-data-wrap {
        width: 430px;
        /* 700px */
    }
}

@media (min-width: 1200px) {

    .sw-sec-signup,
    .sw-signup-blob-box {
        height: 450px;
        /* 600px */
    }

    .sw-signup-blob-back,
    .sw-signup-blob-front {
        top: -130px;
        /* -150px */
        left: 0px;
        /* 0 */

        height: 550px;
        /* 700px */
        width: 550px;
    }

    .sw-signup-thumbnail {
        width: 420px;
        /* 560 */
        height: 450px;
        /* 600 */
    }

    .sw-signup-data-wrap {
        width: 520px;
        /* 700px */
    }
}

@media (min-width: 1400px) {

    .sw-sec-signup,
    .sw-signup-blob-box {
        height: 500px;
        /* 600px */
    }

    .sw-signup-blob-back,
    .sw-signup-blob-front {
        top: -130px;
        /* -150px */
        left: 0px;
        /* 0 */

        height: 620px;
        /* 700px */
        width: 620px;
    }

    .sw-signup-thumbnail {
        width: 467px;
        /* 560 */
        height: 500px;
        /* 600 */
    }

    .sw-signup-data-wrap {
        width: 600px;
        /* 700px */
    }
}

@media (min-width: 1600px) {

    .sw-sec-signup,
    .sw-signup-blob-box {
        height: 600px;
        /* 600px */
    }

    .sw-signup-blob-back,
    .sw-signup-blob-front {
        top: -150px;
        /* -150px */
        left: 0px;
        /* 0 */

        height: 700px;
        /* 700px */
        width: 700px;
    }

    .sw-signup-thumbnail {
        width: 560px;
        /* 560 */
        height: 600px;
        /* 600 */
    }

    .sw-signup-data-wrap {
        width: 700px;
        /* 700px */
    }
}

/* -------------------------------------------------
Chapter 5 Destination list Style
------------------------------------------------- */
.sw-sec-destination-list {
    margin-top: 75px;
}

.sw-sec-gallery {
    margin-bottom: 130px;
}

.sw-dest-list-container {
    margin-top: 60px;
}

.sw-dest-box {
    overflow: hidden;
    border-radius: 18px;
    flex: 0 0 270px;
    /* width: 270px; */
    height: 350px;
    margin: 0px var(--font-nano);
}

.sw-dest-box-sm {
    overflow: hidden;
    border-radius: 18px;
    flex: 0 0 174px;
    /* width: 270px; */
    height: 90px;
    margin-right: 18px;
}

.sw-dest-box-shadow {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent linear-gradient(180deg, #20202000 0%, #20202087 100%) 0% 0% no-repeat padding-box;
    height: 100%;
    width: 100%;
    /* z-index: -1; */
}

.sw-dest-thumbnail {
    border-radius: 18px;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.sw-dest-name {
    position: absolute;
    bottom: 36px;
    left: 36px;
    font-weight: 500;
    font-size: var(--font-xl);
    letter-spacing: 0px;
    color: #fff;
    /* z-index: 0; */
}

.sw-dest-name-sm {
    position: absolute;
    bottom: 18px;
    left: 70px;
    font-weight: 500;
    font-size: var(--font-sm);
    letter-spacing: 0px;
    color: #fff;
    /* z-index: 0; */
}

@media (min-width: 992px) {
    .sw-dest-box-sm {
        flex: 0 0 183px;
    }
}

@media (min-width: 1200px) {
    .sw-dest-box-sm {
        flex: 0 0 226px;
    }
}

@media (min-width: 1400px) {
    .sw-dest-box-sm {
        flex: 0 0 212px;
    }
}

@media (min-width: 1600px) {
    .sw-dest-box-sm {
        flex: 0 0 256px;
    }
}


/* -------------------------------------------------
Chapter 4 Experiences list Style
------------------------------------------------- */
.sw-sec-exp-list {
    /* border: solid 2px crimson; */
    /* margin-top: 110px; */
    margin-top: calc(var(--font-sm) * 5.5);
}

.sw-sec-header {
    /* border: solid 2px orange; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
}

.sw-header-desc {
    font-size: var(--font-3xl);
    line-height: calc(var(--font-3xl) + 1);
    letter-spacing: 0px;
    color: var(--color-four);
}

.sw-header-desc-lg {
    font-size: var(--font-5xl);
    line-height: calc(var(--font-5xl) + 1);
    letter-spacing: 0px;
    color: var(--color-four);
}

.sw-header-desc-lg .highlight {
    font-weight: 600;
    color: var(--color-one);
}

.sw-see-all {
    align-self: flex-end;
}

.sw-see-all a {
    font-size: var(--font-uxs);
    line-height: calc(var(--font-uxs) + 1);
    letter-spacing: 0px;
    color: var(--color-one);
    text-decoration: none;
}

.sw-sec-sub-header {
    margin-top: var(--font-xl);
}

.sw-sub-header-desc {
    font-size: var(--font-xs);
    line-height: calc(var(--font-6xl) + 1);
    letter-spacing: 0px;
    border-bottom: 1px solid #CECECE;

}

.sw-sub-header-desc.one {
    color: var(--color-one);
}

.sw-sub-header-desc.three {
    color: var(--color-three);
}

.sw-sub-header-border {
    display: inline-block;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    padding-bottom: var(--font-sm);
    margin-bottom: -2px;
}

.sw-sub-header-border.one {
    border-color: var(--color-one);
}

.sw-sub-header-border.three {
    border-color: var(--color-three);
}

.sw-exp-list-container,
.sw-exp-carousel-controls,
.sw-dest-carousel-controls,
.sw-testi-carousel-controls {
    margin-top: 62px;
}

.sw-exp-carousel,
.sw-dest-carousel,
.sw-testi-carousel,
.sw-dest-carousel-2 {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.sw-exp-carousel {
    /* padding-left: 10px; */
}

.sw-exp-box {
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    background-color: #fff;
    box-shadow: 0px 3px 22px #00000014;
    border-radius: 20px;
    /* width: 429px; */
    /* height: 568px; */
    padding: var(--font-5xl);
    overflow: hidden;
    margin: var(--font-xs);
}

.sw-exp-box {
    flex: 0 0 330px;
    /* 429px */
}

.sw-exp-thumbnail {
    border-radius: 18px;
    height: 150px;
    /*234px*/
    overflow: hidden;
}

.sw-exp-thumbnail img,
.sw-dest-thumbnail img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* contain-width & cover-height */
    object-position: center center;
}

.sw-pill {
    display: inline-block;
    background-color: var(--color-two);
    border-radius: 15px;
    text-align: center;
    font-size: var(--font-uxs);
    line-height: calc(var(--font-6xl) + 1);
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 3px 16px 2px;
    margin-top: var(--font-xl);
}

.sw-exp-meta-wrap {
    width: 400px;
}

.sw-exp-meta {
    margin-top: var(--font-sm);
}

.sw-exp-meta i,
.sw-exp-meta svg {
    font-size: var(--font-xs);
}

.sw-exp-meta span {
    font-weight: normal;
    font-size: var(--font-uxs);
    line-height: var(--font-md);
    letter-spacing: 0px;
    color: var(--color-four);
    padding-left: 8px;
}

.sw-exp-name {
    font-size: var(--font-md);
    line-height: calc(var(--font-xl) + 1);
    letter-spacing: 0px;
    color: var(--color-four);
    min-height: 57px;
    margin-top: var(--font-md);
    /* border: solid 1px red; */

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.sw-exp-name-lg {
    font-weight: 500;
    font-size: var(--font-5xl);
    line-height: var(--font-2xl);
    letter-spacing: 0px;
    color: var(--color-four);
    margin-top: var(--font-6xl);
    /* border: solid 1px red; */
}

.sw-exp-desc {
    font-size: var(--font-uxs);
    line-height: var(--font-xs);
    letter-spacing: 0px;
    color: var(--color-four);
    min-height: 56px;
    margin-top: 11px;
    /* border: solid 1px blue; */

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.sw-exp-find-link {
    margin-top: 10px;
    text-align: right;
}

.sw-exp-find-link a {
    font-weight: 600;
    font-size: var(--font-uxs);
    line-height: var(--font-xs);
    letter-spacing: 0px;
    color: var(--color-two);
}

.sw-exp-find-link a:hover {
    color: var(--color-one);
}

.sw-exp-carousel-controls,
.sw-dest-carousel-controls,
.sw-testi-carousel-controls {
    display: flex;
    justify-content: flex-end;
}

.sw-exp-cont-btn,
.sw-dest-cont-btn,
.sw-testi-cont-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    background-color: #E3E3E3;
    cursor: pointer;
    margin-left: 18px;
}

.sw-exp-cont-btn:hover,
.sw-exp-cont-btn.active,
.sw-dest-cont-btn:hover,
.sw-dest-cont-btn.active,
.sw-testi-cont-btn:hover,
.sw-testi-cont-btn.active {
    background-color: var(--color-one);
}


@media (min-width: 768px) {
    .sw-sec-header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .sw-see-all {
        align-self: center;
    }

    .sw-exp-box {
        flex: 0 0 314px;
        /* 429px */
    }

    .sw-exp-desc {
        min-height: 46px;
    }

    .sw-exp-name {
        min-height: 63px;
    }
}

@media (min-width: 992px) {
    .sw-exp-box {
        flex: 0 0 433px;
        /* 429px */
    }

    .sw-exp-desc {
        min-height: 49px;
    }

    .sw-exp-thumbnail {
        height: 170px;
        /*234px*/
    }

}

@media (min-width: 1200px) {
    .sw-exp-box {
        flex: 0 0 337px;
        /* 429px */
    }

    .sw-exp-desc {
        min-height: 52px;
    }

    .sw-exp-name {
        min-height: 67px;
    }

    .sw-exp-thumbnail {
        height: 190px;
        /*234px*/
    }
}

@media (min-width: 1400px) {
    .sw-exp-box {
        flex: 0 0 396px;
        /* 429px */
    }

    .sw-exp-desc {
        min-height: 54px;
    }

    .sw-exp-thumbnail {
        height: 210px;
        /*234px*/
    }
}

@media (min-width: 1600px) {
    .sw-exp-box {
        flex: 0 0 429px;
        /* 429px */
    }

    .sw-exp-thumbnail {
        height: 234px;
        /*234px*/
    }
}

/* -------------------------------------------------
Chapter 3 Looking for section Style
------------------------------------------------- */
.sw-sec-looking-for {
    /* border: solid 2px crimson; */
    margin-top: 66px;
}

.sw-look-box {
    box-shadow: 0px 3px 22px #0000000F;
    border-radius: 20px;
    height: 496px;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: calc(var(--bs-gutter-x) * 0.5);
}

.sw-look-box img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* contain-width & cover-height */
    object-position: center center;
}

.sw-look-bgcolor {
    /* border: solid 2px darkorchid; */
    height: 248px;
    width: 100%;
    bottom: 0;
    opacity: 0.7;
}

.sw-look-text {
    /* border: solid 2px fuchsia; */
    height: auto;
    width: 100%;
    bottom: calc(var(--font-lg) * 2);
    text-align: center;
    font-weight: 600;
    font-size: calc(var(--font-xl) + 1);
    line-height: calc(var(--font-6xl) + 1);
    letter-spacing: 0px;
    color: #FFFFFF;
}

.gradiant-blue {
    background: transparent linear-gradient(180deg, #3054A000 0%, #3054A0 100%) 0% 0% no-repeat padding-box;
}

.gradiant-sky {
    background: transparent linear-gradient(180deg, #479AD200 0%, #479AD2 100%) 0% 0% no-repeat padding-box;
}

.gradiant-orange {
    background: transparent linear-gradient(180deg, #FBA70800 0%, #FBA708D9 100%) 0% 0% no-repeat padding-box;
}

@media (min-width: 768px) {
    .sw-sec-looking-for {
        margin-top: 86px;
    }

    .sw-look-box {
        height: 245px;
        margin-bottom: 0px;
    }

    .sw-look-bgcolor {
        height: 122px;
    }
}

@media (min-width: 992px) {
    .sw-sec-looking-for {
        margin-top: 106px;
    }

    .sw-look-box {
        height: 335px;
    }

    .sw-look-bgcolor {
        height: 168px;
    }
}

@media (min-width: 1200px) {
    .sw-sec-looking-for {
        margin-top: 126px;
    }

    .sw-look-box {
        height: 404px;
    }

    .sw-look-bgcolor {
        height: 202px;
    }
}

@media (min-width: 1400px) {
    .sw-sec-looking-for {
        margin-top: 146px;
    }

    .sw-look-box {
        height: 496px;
    }

    .sw-look-bgcolor {
        height: 248px;
    }
}

/* -------------------------------------------------
Chapter 2 Header Style
------------------------------------------------- */
.sw-header-img-home {
    position: relative;
    z-index: 1;
    /* border: solid 1px cyan; */
}

.sw-header-img-home {
    height: 326px;
}

.sw-header-img-child {
    height: 328px;
}

.sw-header-img-home img,
.sw-header-img-child img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* contain-width & cover-height */
    object-position: center center;
}

.sw-header {
    /* border: solid 1px blue; */
    position: fixed;
    top: 0;
    width: 100%;
    height: 118px;
    background: transparent linear-gradient(180deg, #202020 0%, #20202000 100%) 0% 0% no-repeat padding-box;
    opacity: 0.95;
    z-index: 999;
}

header {
    position: relative;
    margin-top: 21px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 0px 4px;
    /* border: solid 2px red; */
    /* background-color: DarkSlateBlue; */
}

.sw-logo {
    /* border: solid 1px white; */
    border-radius: 4px;
    /* flex: 1 1 100%; */
    /* flex: 0 0 150px; */
    /* 215px */
    /* height: 47px; */
    /* 67px */

    width: 120px; /* 150px */
    height: 47px;
    /* margin: 0 auto; */

    /* display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3); */

    /* border: solid 1px blue; */
}

.sw-logo img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* contain-width & cover-height */
    object-position: center center;
}

.navbar-toggler {
    font-size: 26px;
    padding: 4px 8px 1px 8px;
    border: solid 1px #fff;
    border-radius: 4px;
}

.sw-menu {
    /* border: solid 2px green; */
    flex: 0 0 100%;
}

ul.menu {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: #202020;
    /* background-color: MidnightBlue; */
}

ul.menu li {
    /* border: solid 1px lime; */
    list-style: none;
    margin-right: 0px;
    /* 75px */
    padding: 6px 8px;
}

ul.menu li:last-child {
    margin-right: 0px;
}

ul.menu li a {
    text-decoration: none;
    font-weight: normal;
    letter-spacing: 0px;
    color: #fff;
}

ul.menu li.active a,
ul.menu li:hover a {
    font-weight: 600;
}

.sw-language {
    /* border: solid 2px darkred; */
    margin-left: 8px;
    flex: 1;
}

.sw-action {
    /* border: solid 2px purple; */
    flex: 1;
    /* width: 100px; */
    /* 234px */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.sw-search,
.sw-user {
    /* border: solid 1px red; */
    width: 30px;
    text-align: center;
    cursor: pointer;
}

.sw-search {
    margin-right: 30px;
}

.sw-header-blob-back,
.sw-header-blob-front,
.sw-header-blob-over,
.sw-signup-blob-back,
.sw-signup-blob-front,
.sw-newsletter-blob-back,
.sw-newsletter-blob-front {
    position: absolute;
    /* border: solid 2px red; */
}

.sw-header-blob-back {
    z-index: 2;
}

.sw-header-blob-front {
    z-index: 3;
}

.sw-header-blob-back,
.sw-header-blob-front {
    top: 26px;
    height: 300px;
    width: 300px;
}

.sw-header-blob-back svg {
    opacity: 0.42;
}

.sw-header-blob-front svg {
    opacity: 0.59;
}

.sw-header-blob-over {
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 150px;
    z-index: 4;
}

.sw-tagline {
    font-size: 18px;
    line-height: 18px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.sw-sub-tagline {
    margin-top: var(--font-lg);
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.sw-discover {
    margin-top: calc(var(--font-md) * 2);
}

.nav-lang {
    /* border: solid 1px yellow; */
    cursor: pointer;
    position: relative;
}

.nav-lang-control {
    /* border: solid 1px cyan; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.nav-lang-control.active .sw-lang-display {
    color: #fff;
}

.nav-lang-options {
    border: solid 1px var(--bs-gray-500);
    border-radius: 14px;
    background-color: #fff;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 1;
    box-shadow: 0px 3px 22px #00000014;
}

.nav-lang-list {
    padding: 6px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-top: solid 1px var(--bs-gray-500);

}

.nav-lang-list:first-child {
    border-top: none;
}

.sw-lang-display {
    text-transform: capitalize;
    margin-left: 4px;
    font-weight: normal;
    color: var(--color-four);
    font-size: var(--font-xs);
    /* uxs */
    /* border: solid 1px blue; */
    padding-top: 2px;
}

.nav-lang:hover .sw-lang-display {
    /* color: greenyellow; */
    /* font-weight: 600; */
}

.sw-flag {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    overflow: hidden;
    /* border: solid 1px red; */
}

.sw-flag img {
    vertical-align: unset;
}


@media (min-width: 768px) {

    .sw-tagline {
        font-size: calc(var(--font-3xl) * 2);
        line-height: calc(var(--font-3xl) * 2);
    }

    .sw-sub-tagline {
        font-size: calc(var(--font-md) + 1);
        line-height: calc(var(--font-xl) + 1);
    }

    header {
        flex-direction: row;
        align-items: center;
        padding: 0px;
    }

    .sw-header-img-home {
        height: 526px;
    }

    .sw-header-img-child {
        height: 328px;
    }

    .sw-header-blob-back,
    .sw-header-blob-front {
        left: -60px;
        height: 500px;
        width: 500px;
    }

    .sw-header-blob-over {
        top: 190px;
    }

    .sw-logo {
        flex: 0 0 150px;

        
        height: unset;
        margin: unset;
        /* 215px */
        /* height: 47px; */
        /* 67px */
    }

    .sw-menu {
        flex: unset;
    }

    ul.menu {
        flex-direction: row;
        background-color: unset;
    }

    ul.menu li {
        margin-right: 30px;
        /* 75px */
        padding: 0px;
    }

    .sw-language {
        margin-left: unset;
        flex: unset;
    }

    .sw-action {
        flex: unset;
        width: 100px;
        /* 234px */
    }
}

@media (min-width: 992px) {

    .sw-header-img-home {
        height: 726px;
    }

    .sw-header-img-child {
        height: 428px;
    }

    .sw-header-blob-back,
    .sw-header-blob-front {
        left: -80px;
        height: 700px;
        width: 700px;
    }

    .sw-header-blob-over {
        top: 350px;
    }
}

@media (min-width: 1200px) {

    .sw-header-img-home {
        height: 926px;
    }

    .sw-header-img-child {
        height: 628px;
    }

    .sw-header-blob-back,
    .sw-header-blob-front {
        left: -105px;
        height: 900px;
        width: 900px;
    }

    .sw-header-blob-over {
        top: 450px;
    }

    .sw-logo {
        flex: 0 0 180px;
        /* 215px */
        /* height: 56px; */
        /* 67px */
    }

    ul.menu li {
        margin-right: 50px;
        /* 75px */
    }

    .sw-action {
        width: 150px;
        /* 234px */
    }
}

@media (min-width: 1400px) {

    .sw-header-img-home {
        height: 1026px;
    }

    .sw-header-img-child {
        height: 728px;
    }

    .sw-header-blob-back,
    .sw-header-blob-front {
        left: -120px;
        height: 1000px;
        width: 1000px;
    }

    .sw-header-blob-over {
        top: 500px;
    }

    .sw-logo {
        flex: 0 0 200px;
        /* 215px */
        /* height: 62px; */
        /* 67px */
    }

    ul.menu li {
        margin-right: 65px;
        /* 75px */
    }

    .sw-action {
        width: 200px;
        /* 234px */
    }
}

@media (min-width: 1600px) {



    .sw-logo {
        flex: 0 0 215px;
        /* 215px */
        /* height: 67px; */
        /* 67px */
    }

    ul.menu li {
        margin-right: 75px;
        /* 75px */
    }

    .sw-action {
        width: 234px;
        /* 234px */
    }
}





@media (min-width: 768px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}

@media (min-width: 1600px) {}