.route-loader {
  position: fixed;
  height: 100%;
  width: 100%;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  max-width: 100px;
  max-height: 100px;
  z-index: 5;
}
a.sw-pill:hover {
  color: #fff;
}
.wc-datepicker .react-datepicker-wrapper {
  display: block;
}
.highlight-field {
  border: 1px solid red !important;
}
label.error-message {
  color: red;
}
label.success-message {
  color: #198754;
  font-weight: 600;
}
.sw-check-opt-desc {
  justify-content: left !important;
}
.Toastify__toast-container {
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
  width: 100% !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
}

.Toastify__toast {
  border-radius: 0 !important;
}

.Toastify__close-button > svg {
  fill: currentColor !important;
  height: 28px !important;
  width: 26px !important;
  position: relative !important;
  top: 10px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#root {
  min-height: 100vh;
}
.route-loader {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 5;
  background: #1614143d;
  max-width: 100%;
  max-height: 100%;
}
.route-loader svg {
  max-width: 110px;
  max-height: 110px;
  position: fixed;
  left: calc(50% - 55px);
  top: calc(50% - 55px);
}
.iframe-container {
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
}
.pdf-iframe {
  width: 100%;
  height: 1920px;
  border: 0px;
  zoom: 1;
}
.max-w-180 {
  max-width: 180px !important;
}
.confirm-icon {
  font-size: 120px;
}
.sw-logo {
  border: 0 !important;
  background-color: transparent !important;
}
.hide-slider-arrow .carousel-control-prev,
.hide-slider-arrow .carousel-control-next,
.hide-slider-arrow .slick-arrow {
  display: none !important;
}
.sw-logo img {
  object-fit: contain !important;
  padding-right: 15px;
}
/* .color-1{
  color: var(--color-one);
} */
